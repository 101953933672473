<template>
  <div class="container">
    <div class="card pb-0">
      <el-form :disabled="loading" :inline="true" class="screen-form">
        <el-form-item label="患者" class="pb-10">
          <el-input
            clearable
            v-model="form.name"
            placeholder="支持姓名或编号"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="就诊类型" class="pb-10">
          <el-select
            clearable
            v-model="form.type"
            fit-input-width
            placeholder="查看全部"
            style="width: 120px"
          >
            <el-option label="计划复诊" value="1"></el-option>
            <el-option label="临时复诊" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="移植类型" class="pb-10">
          <el-select
            clearable
            v-model="form.transplant"
            fit-input-width
            placeholder="查看全部"
            style="width: 120px"
          >
            <el-option
              v-for="item in transplant"
              :key="'transplant_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" class="pb-10">
          <el-select
            clearable
            v-model="form.state"
            fit-input-width
            placeholder="查看全部"
            style="width: 100px"
          >
            <el-option label="待反馈" value="1"></el-option>
            <el-option label="已反馈" value="2"></el-option>
            <el-option label="已删除" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="pb-10">
          <el-button type="primary" @click="getNumber">
            <el-icon><search /></el-icon>
            <span>筛选</span>
          </el-button>
          <el-button @click="clean">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="card table-card no-select" v-loading="loading">
      <template v-if="!error">
        <el-table
          :data="list"
          style="width: 100%; border-radius: 8px"
          :row-style="{ cursor: 'pointer' }"
          v-if="list.length > 0"
          @row-click="openFollow"
        >
          <el-table-column prop="name" label="患者姓名" width="100" fixed />
          <el-table-column
            prop="transplantTypeName"
            label="移植类型"
            width="100"
          />
          <el-table-column prop="date" label="就诊日期" width="110" />
          <el-table-column label="就诊类型" width="100">
            <template #default="scope">
              <div v-if="scope.row.type === 1">计划复诊</div>
              <div v-if="scope.row.type === 2">临时复诊</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="hospitalName"
            label="就诊医院"
            min-width="200"
          />
          <el-table-column prop="department" label="就诊科室" width="100" />
          <el-table-column prop="doctor" label="接诊医生" width="100" />
          <el-table-column prop="addTime" label="提交时间" width="170" />
          <el-table-column label="状态" align="center" width="80" fixed="right">
            <template #default="scope">
              <el-tag type="warning" effect="dark" v-if="scope.row.state === 1"
                >待反馈</el-tag
              >
              <el-tag
                type="success"
                effect="dark"
                v-else-if="scope.row.state === 2"
                >已反馈</el-tag
              >
              <el-tag type="info" effect="dark" v-else>已删除</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-empty class="loading" v-else description="暂无符合的复诊记录">
          <el-button @click="clean">重置筛选条件</el-button>
        </el-empty>
      </template>
      <el-result
        v-else
        icon="error"
        title="加载失败"
        style="padding-top: calc(50vh - 220px)"
      />
    </div>
    <div class="card mb-0" v-if="!loading && total > 0">
      <div class="page-number">每页{{ number }}条 / 共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="number"
        @current-change="getList"
      />
    </div>
  </div>
</template>

<script>
import { Search } from "@element-plus/icons";
import { common, follow } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "ExternalList",
  components: { Search },
  data: () => ({
    list: [],
    form: {
      name: "",
      type: "",
      transplant: "",
      state: "",
    },
    error: false,
    loading: true,
    transplant: [],
    number: 13,
    total: 0,
    page: 0,
  }),
  methods: {
    init() {
      let height = document.body.clientHeight;
      if (height > 768) this.number = 14 + parseInt((height - 768) / 40);
      this.getNumber();
      this.initCache();
    },
    initCache() {
      let cache = localStorage.getItem("cache_transplant");
      if (cache) this.transplant = JSON.parse(cache);
      common.initPage("/template/list").then((res) => {
        if (res.state) {
          localStorage.setItem("cache_transplant", JSON.stringify(res.data));
          this.transplant = res.data;
        }
      });
    },
    getNumber() {
      this.loading = true;
      follow
        .getExternalNumber(this.form)
        .then((res) => {
          this.total = res.data;
          if (this.total > 0) this.getList(1);
          else {
            this.list = [];
            this.loading = false;
          }
        })
        .catch(() => {
          this.total = 0;
          this.error = true;
          this.loading = false;
        });
    },
    getList(page) {
      this.page = page;
      follow
        .getExternalList(this.form, page, this.number)
        .then((res) => {
          setTimeout(() => {
            for (let i in res.data) {
              this.buildDate(res.data[i], "date", "yyyy-mm-dd");
              this.buildDate(res.data[i], "addTime", "yyyy-mm-dd HH:MM:SS");
            }
            this.list = res.data;
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    buildDate(obj, name, code) {
      obj[name] = date.format(obj[name], code);
    },
    openFollow(row) {
      this.$router.push(
        "/patient/" + row.pid + "/follow?id=" + (row.id ? row.id : row)
      );
    },
    clean() {
      this.form = {
        name: "",
        type: "",
        transplant: "",
        state: "",
      };
      this.getNumber();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.screen-form .el-form-item {
  margin: 0 10px 0 0;
}

.table-card {
  min-height: calc(100vh - 200px);
}

.loading {
  margin-top: calc(50vh - 300px);
}

.page-number {
  align-items: center;
  font-size: 14px;
  display: flex;
  color: #999;
  height: 35px;
  float: right;
}
</style>
